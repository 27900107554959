import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
// import LayoutInner from "common/components/Layout";
// import Titlebar from "common/components/Titlebar";
// import FAQToggle from "common/components/FAQs";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairBonding = () => {

  const seoData = {
    title: 'Hair Bonding for Women in Delhi - Radiance Hair Studio',
    description: 'Looking for the best hair bonding for women in Delhi? Radiance Hair Studio is gonna be the proper fit for you. Come and try our services.',
    keywords: ['Hair bonding for women in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "mainEntityOfPage": "Service Page",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/hair-bonding-for-women.png",
      "https://www.radiancehairstudio.com/hair-bonding-for-women1.png",
      "https://www.radiancehairstudio.com/hair-bonding-for-women2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "disambiguatingDescription": "Hair bonding for women in Delhi",
    "additionalType": "https://www.radiancehairstudio.com/hair-bonding-for-women.php",
    "category": "Hair Bonding",
    "description": "Looking for the best hair bonding for women in Delhi? Radiance Hair Studio is the proper fit for you. Come and try our services.",
    "serviceType": "Hair Bonding for Women"
  };
  

  // const faqs = [
  //   {
  //     title: "What is the procedure involved in the process of hair weaving?",
  //     description:
  //       "Hair weaving is the easiest procedure of all hair treatments. Extra human hair or synthetic Forbes are clipped or braided to the existing hair shafts to cover the baldness.",
  //   },
  //   {
  //     title: "Is the process of hair weaving harmful or painful?",
  //     description:
  //       "The process of hair weaving doesn't involve the sticking of artificial hairlines to the scalp. Thus no chemical use is involved, which makes it harmless to the scalp.",
  //   },
  //   {
  //     title: "How long does the weaving stay on?",
  //     description:
  //       "One can wear their hair weaving desirably. It may vary from a week to few months. Depending upon their styling and care.",
  //   },
  //   {
  //     title: "Will my hair still grow after adopting a hair weaving treatment?",
  //     description:
  //       "The hair weaving process is just extra hair shafts that are being braided or clipped to cover up baldness. Of course ones hair keeps growing as usual even after adopting the same.",
  //   },
  // ];

  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
        img: "/hair-bonding-for-women.png",
        content: (
            <div>
                <h2>Hair Bonding</h2>
                <p>Remember those days of luscious locks and thick braids? While the question makes you DAYDREAM, say HOLA to your happening lifestyle!! All those late night shots and savory cravings are responsible for the blunder caused. And when you are here in India nothing is good enough to fight the scorching heat. Those tresses are bound to fall off.</p>
                <h2>Style your Hair with Hair Bonding!Hair Bonding</h2>
                <p>The major transformation from BALD to BOLD is just the CAPITAL away from you. RADIANCE studio provides the best hair fixing in Delhi. Now talking about <a href="/hair-weaving-for-women.php" style={{ color: "#373737"}}>HAIR WEAVING!</a> Let me make it clear for you. HAIR WEAVING is basically a manual but painless process to add extra hair. The extra hair is braided and woven to the existing hair to cover up all the scalp visible. The best quality human hair being treated chemically to make it further more refined to be used in weaving. Without the surgical indulgence hair weaving is can be the best step taken to attain the thick hair. The manual looping and weaving of the extra hair with the existing shafts is less time consuming and easier. There are types and techniques of weaving the extra hair to add volume. Micro bid, micro loop, shoelace expansion, knotting are some of the processes of hair weaving that are usually adopted by customers.</p>
                <p>TRANSFORMATION would never have been easier! The plus point of being added up by the 100% natural human hair makes a visible difference.

                </p>
            </div>
        )
    }
];

const sectionS3 = [
    {
        smallImg: "/hair-bonding-for-women1.png",
        largeImg: "/hair-bonding-for-women2.png",
        content: (
            <div>
                <h2>The BENEFITS of Hair Weaving!</h2>
                <p>Though you can never get enough of India, the weather here is surely standing on the way to achieve that beautiful perfect MANE. Here you go with some amazing reasons and BENEFITS to try out the same.</p>
                <ul>
                    <li> Women are the future! Working hard and earning their dream job. Though the same has contributed to their improper lifestyles. Hair fall directly connects to the same. In the busy schedule when styling the hair cannot take off the time, also the style should not be compromised upon. Hair weaving helps with the same. Making the hair perfectly stylish and look hustle free adding glamour. We can assure RADIANCE offers the beat HAIR WEAVING in Delhi tough.</li>
                    <li> If you are someone who has a <a href="/services.php">FASHIONISTA</a> character and love styling your hair like CRAZY! but unable to do so because of the scanty hair, WEAVING is the go-to solution. You can wear one desirably and change when you would like to. </li>
                    <li> Unlike many other non-surgical hair treatment options, HAIR Weaves blend in perfectly with one's natural hair. The same is done by manually weaving the extra hairs to the naturally existing hair on your scalp.</li>
                    <li>The process of HAIR WEAVING doesn't involve any use of extra chemical products thus making it a carefree solution to all your hair problem. Absolutely painless, less time consuming and adding character to your personality. WHAT MORE DO YOU WANT!?  </li>
                </ul>
                <p><a href="/">Radiance</a> can assure a hostel free easy and confident life with the treatments provided with the addition being the use of natural human hair in every treatment process.</p>
            </div>
        )
    }
];

const sectionS4 = [
    {
        title: "Hair Bonding FAQs!",
        description: "By now we may have given you enough information to try the HAIR WEAVING and gift yourself those gorgeous locks BACK! But if not, we know exactly what's holding you back! RADIANCE is here to answer all your questions regarding the same.",
        description2: "Hope that we are able to answer all the question bothering you to get your hair weaved. We hope the RADIANCE services does make hair fixing in Delhi easier. You are always welcomed with the free consultation services we provide.",
        faq: [
            {
                title: "What is the procedure involved in the process of hair weaving?",
                description: "Hair weaving is the easiest procedure of all hair treatments. Extra human hair or synthetic Forbes are clipped or braided to the existing hair shafts to cover the baldness."
            },
            {
                title: "Is the process of hair weaving harmful or painful?",
                description: "The process of hair weaving doesn't involve the sticking of artificial hairlines to the scalp. Thus no chemical use is involved, which makes it harmless to the scalp."
            },
            {
                title: "How long does the weaving stay on?",
                description: "One can wear their hair weaving desirably. It may vary from a week to few months. Depending upon their styling and care."
            },
            {
                title: "Will my hair still grow after adopting a hair weaving treatment?",
                description: "The hair weaving process is just extra hair shafts that are being braided or clipped to cover up baldness. Of course ones hair keeps growing as usual even after adopting the same."
            }
        ],
    }
];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Bonding for Women's" banner="/hair-bonding.png" />
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Bonding for Women's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairBonding;
